import React, { useState, useContext , useEffect } from "react";
import { Link, NavLink, useParams, useNavigate } from "react-router-dom";
import { WebAssetsContext } from "../../App";
import Swal from 'sweetalert2';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import axios from 'axios';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { getActivebranches , loggedInUserDetails } from "../../functions/Function";

const Useradd = (props) => {
  const [companies, setCompanies] = useState({});
  const [staffusers, setStaffusers] = useState({});
  const [countries, setCountries] = useState({});
  const [branchlist, setBranchlist] = useState({});
  const WebAssets = useContext(WebAssetsContext);
  const api = WebAssets.apiURL;
  const navigate = useNavigate();
  const params = useParams();
  const [disabled, setDisabled] = useState(false);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [status, setStatus] = useState('1');
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [company, setCompany] = useState("");
  const [passportnumber, setPassportnumber] = useState("");
  const [pmnumber, setPmnumber] = useState("");
  const [cmnumber, setCmnumber] = useState("");
  const [gendar, setGendar] = useState("Male");
  const [dob, setDob] = useState('');
  const [passportexpdate, setPassportexpdate] = useState('');
  const [userid, setUserid] = useState("");
  const [country, setCountry] = useState("");
  const [branch, setBranch] = useState('');
  const [uploadfileview, setUploadfileview] = useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [progress, setProgress] = React.useState(0);
  const [previmage, setPrevimage] = React.useState(null);
  const handleFileSelect = async(event) => {
    let file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios({
        method: "post",
        url: api + "uploadfile",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
          
         
        },
      }).then(res => { 
        setProgress(0);
        setPrevimage(res.data.response.fullpath);
        setSelectedFile(res.data.response.name);
      });
     
    } catch(error) {
      console.log(error)
    }

  }

  const getCountries = async () => {
    const res = await fetch(api + "getCountries", {
      method: "GET",
    });
    let resJson = await res.json();
    if(res.status === 200)
    {
      if(resJson.status == true)
      { 
        setCountries(resJson.response)
      }
    }
  }

  const getActivebranches = async () => {
    const res = await fetch(api + "getActivebranches", {
      method: "GET",
    });
    let resJson = await res.json();
    if(res.status === 200)
    {
      if(resJson.status == true)
      { 
        setBranchlist(resJson.response)
      }
    }
  }


  const getActiveCompanies = async () => {
    const res = await fetch(api + "getActivecompanies", {
      method: "GET",
    });
    let resJson = await res.json();
    if(res.status === 200)
    {
      if(resJson.status == true)
      { 
        setCompanies(resJson.response)
      }
    }
  }

  const staffAndAdminusers = async () => {
    const res = await fetch(api + "staffAndAdminusers", {
      method: "GET",
    });
    let resJson = await res.json();
    if(res.status === 200)
    {
      if(resJson.status == true)
      { 
        setStaffusers(resJson.response)
      }
    }
  }

  const errortoast = (text) => {
    toast.error(text,
      {
        autoClose: 3000,
        transition: Zoom,
        theme: "colored",
        closeOnClick: true,
        pauseOnHover: true,
      }
    );
  };

  const actvieStatus = () => {
    setStatus('1');
  }
  
  const inactvieStatus = () => {
    setStatus('0');
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    try {
      let res = await fetch(api + "addUser", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          first_name: fname,
          last_name: lname,
          email: email,
          phone: mobileNumber,
          address: address,
          city: city,
          status: status,
          gendar: gendar,
          state: state,
          company: company,
          passportnumber: passportnumber,
          pmnumber: pmnumber,
          cmnumber: cmnumber,
          added_by: userid,
          passport_photo: selectedFile,
          country: country,
          passportexpdate: passportexpdate,
          dob: passportexpdate,
          branch_id: branch
        }),
      });
      let output = await res.json();
      setDisabled(false);
      if (res.status === 200) {
        if (output.status === 2) {
          getvalidationErr(output.response);
        }
        if (output.status === false) {
            errortoast(output.response);
        }
        if (output.status === true) {
          setFname('');
          setLname('');
          setEmail('');
          setMobileNumber('');
          setCity('');
          setState('');
          setCompany('');
          setPassportnumber('');
          setPmnumber('');
          setCmnumber('');
          setGendar('');
          setCountry('');
          setDob('');
          setBranch('');
          setPassportexpdate('');
          setDisabled(false);
          Swal.fire({
            title: 'Success',
            text: 'Client added successfully!',
            icon: 'success'
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/admin/clients");
            }
          });
        }

      }
    } catch (err) {
      setDisabled(false);
    }
  };

  const getvalidationErr = (errors) => {
    const errorsConvertInArry = Object.values(errors);
    let collectAllValidationErr = [];
    errorsConvertInArry.forEach((error, index) => {
      collectAllValidationErr.push(<div key={index}>{error}</div>);
    });
    const storeAllValidtionErr = (
      <>
        {collectAllValidationErr}
      </>
    );
    errortoast(storeAllValidtionErr);
  }

  useEffect(() => {
    getActiveCompanies();
    getActivebranches();
    getCountries();
    staffAndAdminusers();

    if(loggedInUserDetails('role') == 'staff')
    {
      setBranch(loggedInUserDetails('branch_id'));
      setUserid(loggedInUserDetails('id'));
    }
    
  }, [])
  

  return (
    <>
      <ToastContainer />
      <div className="section-body">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4>Add Client Information</h4>
                <div className="card-header-form">
                  <button onClick={() => navigate("/admin/clients")} className="btn btn-icon icon-left btn-primary"><i className="fas fa-arrow-right"></i> Back</button>
                </div>
              </div>
              <div className="card-body p-4">

                <form onSubmit={handleSubmit} id="create-course-form">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Nom <small className="text-danger">*</small></label>
                        <input
                          type="text"
                          value={fname}
                          placeholder="Nom"
                          onChange={(e) => setFname(e.target.value)}
                          className="form-control"
                        />
                        </div>
                      </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Prenom</label>
                        <input
                          type="text"
                          value={lname}
                          placeholder="Prenom"
                          onChange={(e) => setLname(e.target.value)}
                          className="form-control"
                        />
                        </div>
                      </div>

                      
                    {loggedInUserDetails('role') == 'administrator' && 
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Added By <small className="text-danger">*</small></label>
                        <select className="form-control" onChange={(e) => setUserid(e.target.value)}   defaultValue="">
                          <option value="">--Select Added By User--</option>
                          {staffusers.length > 0 ? staffusers.map((option , i) => (
                            <option key={i} value={option.id}>{option.id} | {option.name} </option>
                          )) : ''}
                        </select>

                      </div>
                    </div>
                     }
                      
                  <div className={loggedInUserDetails('role') == 'administrator' ? 'col-sm-3' : 'col-sm-6' }>
                    <div className="form-group">
                      <label>Gendar <small className="text-danger">*</small></label>
                      <div className="row">
                        <div className="col-sm-3">
                          <input type="radio" id="setGendarMale" name="gendar" className=""
                            value="Male"
                            checked={gendar === 'Male'}
                            onChange={() =>  setGendar('Male')}

                          /> <label htmlFor="setGendarMale">Male</label>
                        </div>
                        <div className="col-sm-3">
                          <input type="radio" id="setGendarFemale" name="gendar" className="custom-control-inpu"
                            value="Female"
                            checked={gendar === 'Female'}
                            onChange={() => setGendar('Female')}
                          /> <label htmlFor="setGendarFemale">Female</label>
                        </div>
                      </div>
                      </div>
                  </div>

                  <div className={loggedInUserDetails('role') == 'administrator' ? 'col-sm-3' : 'col-sm-6' }>
                      <div className="form-group">
                        <label>Date Of Birth<small className="text-danger">*</small></label>
                        <input
                          type="date"
                          value={dob}
                          placeholder="Date Of Birth"
                          onChange={(e) => setDob(e.target.value)}
                          className="form-control"
                        />
                        </div>
                      </div>

                  <div className="col-sm-6">
                      <div className="form-group">
                        <label>Country <small className="text-danger">*</small></label>
                        <select className="form-control" onChange={(e) => setCountry(e.target.value)}   defaultValue="">
                          <option value="">--Select Country--</option>
                          {countries.length > 0 ? countries.map((option , i) => (
                            <option key={i} value={option.id}>{option.country_name} </option>
                          )) : ''}
                        </select>

                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>State</label>
                        <input
                          type="text"
                          value={state}
                          placeholder="State"
                          onChange={(e) => setState(e.target.value)}
                          className="form-control"
                        />

                      </div>
                    </div>
                  
                  <div className="col-sm-3">
                      <div className="form-group">
                        <label>City </label>
                        <input
                          type="text"
                          value={city}
                          placeholder="City"
                          onChange={(e) => setCity(e.target.value)}
                          className="form-control"
                        />

                      </div>
                    </div>

                   

                    

                    

                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>Address </label>

                        <textarea
                          value={address}
                          placeholder="Address"
                          onChange={(e) => setAddress(e.target.value)}
                          className="form-control"
                        >

                        </textarea>
                        </div>
                    </div>

                    
                    
                    
                    <div className={loggedInUserDetails('role') == 'administrator' ? 'col-sm-3' : 'col-sm-6' }>
                      <div className="form-group">
                        <label>Company <small className="text-danger">*</small></label>
                        <select className="form-control" onChange={(e) => setCompany(e.target.value)}   defaultValue="">
                          <option value="">--Select Company--</option>
                          {companies.length > 0 ? companies.map((option , i) => (
                            <option key={i} value={option.id}>{option.title}</option>
                          )) : ''}
                        </select>

                      </div>
                    </div>
                    {loggedInUserDetails('role') == 'administrator' &&
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>Branch<small className="text-danger">*</small></label>
                        <select className="form-control" onChange={(e) => setBranch(e.target.value)}   defaultValue="">
                          <option value="">--Select Branch--</option>
                          {branchlist.length > 0 ? branchlist.map((option) => (
                            <option value={option.id}>{option.title}</option>
                          )) : ''}
                        </select>

                      </div>
                    </div>

                            
                    }



                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>Passport Number <small className="text-danger">*</small></label>
                        <input
                          type="text"
                          value={passportnumber}
                          placeholder="Passport Number"
                          onChange={(e) => setPassportnumber(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>Passport Expire Date<small className="text-danger">*</small></label>
                        <input
                          type="date"
                          value={passportexpdate}
                          placeholder="Passport Expire Date"
                          onChange={(e) => setPassportexpdate(e.target.value)}
                          className="form-control"
                        />
                        </div>
                      </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Email Address</label>
                        <input
                          type="text"
                          value={email}
                          placeholder="Email"
                          onChange={(e) => setEmail(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Phone</label>
                        <input
                          type="text"
                          value={mobileNumber}
                          placeholder="Mobile Number"
                          onChange={(e) => setMobileNumber(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>


                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Passenger membership number</label> 
                        (<small>Add comma separated values to add multiple numbers EX. 12345 , 67890 , 11111</small>)
                        <input
                          type="text"
                          value={pmnumber}
                          placeholder="Passenger Membership Number"
                          onChange={(e) => setPmnumber(e.target.value)}
                          className="form-control"
                        />

                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Company membership number </label>
                        (<small>Add comma separated values to add multiple numbers EX. 12345 , 67890 , 11111</small>)
                        <input
                          type="text"
                          value={cmnumber}
                          placeholder="Company Membership Number"
                          onChange={(e) => setCmnumber(e.target.value)}
                          className="form-control"
                        />

                      </div>
                    </div>

                   
                  
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>Status</label>
                        <div className="row">
                          <div className="col-sm-3">
                            <input type="radio" id="statusActive" name="status" className=""
                              value="1"
                              checked={status === '1'}
                              onChange={actvieStatus}

                            /> <label htmlFor="statusActive">Active</label>
                          </div>
                          <div className="col-sm-3">
                            <input type="radio" id="statusInactive" name="status" className="custom-control-inpu"
                              value="0"
                              checked={status === '0'}
                              onChange={inactvieStatus}
                            /> <label htmlFor="statusInactive">Inactive</label>
                          </div>
                        </div>



                      </div>
                    </div>

                    <div className="col-sm-12">
                    <label>Passport Photo <small className="text-danger">*</small></label> 
                    <div className="d-flex">
                        <div>
                            <div id="image-preview" className="image-preview">
                                <label htmlFor="image-upload" id="image-label">Choose File</label>
                                <input accept="image/*" type="file" name="image" id="image-upload" onChange={handleFileSelect} />
                          </div>
                            <div className="w-100 mt-2">{progress > 0 ? <ProgressBar animated width={100}  variant="success" label={`${progress}%`}  now={progress} /> : ''}</div>
                        </div>
                        {previmage != null ?
                        <div className="w-250 pl-3">
                              <img width={250} src={previmage} />
                        </div>
                        : '' }
                    </div>
                    
                        
                        
                    </div>
                 

                    <div className="col-12">
                      <div className="card-footer text-center">
                        <button className="btn btn-primary mr-1" type="submit" disabled={disabled}> {disabled == false ? 'Submit' : 'Submiting...'} </button>

                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>

  );
}

export default Useradd;
