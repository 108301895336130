import React, { useState, useEffect, useContext } from "react";
import {useParams, useNavigate } from "react-router-dom";
import { WebAssetsContext } from "../../App";
import  {  changeDateFotmate , filebasepath , loggedInUserDetails }  from "../../functions/Function";

const Userview = (props) => {
  const WebAssets = useContext(WebAssetsContext);
  const api = WebAssets.apiURL;
  const navigate = useNavigate();
  const params = useParams();
  const [id, setId] = useState(params.id);
  const [user, setUser] = useState(null);
  const getuserdata = async () => {
    const res = await fetch(api + "getUser", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: id }),
    });
    let resJson = await res.json();
    return resJson;
  }

  const getUser = async () => {
    try {
      const resJson = getuserdata();
      resJson.then(function (output) {
        if (output.status === true) {
          setUser(output.response);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUser();
  }, [id])
  return (
    <>
      {user != null  ? 
      <div className="section-body">
          <div className="row">
              <div className="col-12">
                  <div className="card">
                        <div className="card-header">
                          <h4></h4>
                          <div className="card-header-form">
                            <button onClick={() => navigate("/admin/clients")} className="btn btn-icon icon-left btn-primary"><i className="fas fa-arrow-right"></i> Back</button>
                          </div>
                        </div>
                      <div className="card-body pl-4 pr-4 pb-4 pt-0">
                          <div className="row m-1">
                            <div className="col-sm-12 p-1">
                                <h5 className="font-bolder display-7">Client Details</h5> 
                            </div>
                              <div className="col-md-6 col-sm-12 p-1 mb-2">
                               
                                  <div className="custom-grid">
                                      <div className="cw1 font-bolder">First Name</div>
                                      <div className="cw">{user.first_name} </div>
                                  </div>

                                  <div className="custom-grid">
                                      <div className="cw1 font-bolder">Last Name</div>
                                      <div className="cw">{user.last_name} </div>
                                  </div>
                                  
                                  <div className="custom-grid">
                                    <div className="cw1 font-bolder">Gendar</div>
                                    <div className="cw">{user.gendar ? user.gendar: 'No Added' }</div>
                                  </div>

                                  <div className="custom-grid">
                                    <div className="cw1 font-bolder">Date Of Birth</div>
                                    <div className="cw">{user.dob ? changeDateFotmate(user.dob , 'D MMMM YYYY'): 'No Added' }</div>
                                  </div>

                                  <div className="custom-grid">
                                    <div className="cw1 font-bolder">City</div>
                                    <div className="cw">{user.city ? user.city: 'No Added' }</div>
                                  </div>

                                  <div className="custom-grid">
                                    <div className="cw1 font-bolder">State</div>
                                    <div className="cw">{user.state ? user.state: 'No Added' }</div>
                                  </div>

                                  <div className="custom-grid">
                                    <div className="cw1 font-bolder">Country</div>
                                    <div className="cw">{user.country_name ? user.country_name: 'No Added' }</div>
                                  </div>

                                  <div className="custom-grid">
                                    <div className="cw1 font-bolder">Address</div>
                                    <div className="cw">{user.address ? user.address: 'No Added' } </div>
                                  </div>

                                  <div className="custom-grid">
                                    <div className="cw1 font-bolder">Added By</div>
                                    <div className="cw">{user.added_by_name ? user.added_by_name: 'No Added' } </div>
                                  </div>

                                  <div className="custom-grid">
                                    <div className="cw1 font-bolder">Branch</div>
                                    <div className="cw">{user.branch_name ? user.branch_name: 'No Added' } </div>
                                  </div>
                                  
                                  <div className="custom-grid">
                                    <div className="cw1 font-bolder">Registered Date</div>
                                    <div className="cw">{changeDateFotmate(user.created_date, 'DD-MM-YYYY hh:mm:ss a')}</div>
                                  </div>
                                </div>
                              <div className="col-md-6 col-sm-12  p-1 mb-2">

                                  <div className="custom-grid">
                                    <div className="cw1 font-bolder">Company</div>
                                    <div className="cw">{user.company_name}</div>
                                  </div>

                                  <div className="custom-grid">
                                    <div className="cw1 font-bolder">Passport Number</div>
                                    <div className="cw">{user.passport_number}</div>
                                  </div>

                                  <div className="custom-grid">
                                    <div className="cw1 font-bolder">Passport Expire Date</div>
                                    <div className="cw">{changeDateFotmate(user.passport_exp_date , 'D MMMM YYYY')}</div>
                                  </div>
                                  { loggedInUserDetails('role') == 'administrator' &&
                                        <>
                                          <div className="custom-grid mt-4">
                                            <div className="cw1 font-bolder">Email</div>
                                              <div className="cw">{user.c_email}  
                                            </div>
                                          </div>

                                          <div className="custom-grid">
                                            <div className="cw1 font-bolder">Phone</div>
                                            <div className="cw">{user.c_phone}</div>
                                          </div>
                                        </>
                                    }

                                  <div className="custom-grid">
                                    <div className="cw1 font-bolder">Passenger membership number</div>
                                    <div className="cw">{user.passenger_membership_number}</div>
                                  </div>

                                  <div className="custom-grid">
                                    <div className="cw1 font-bolder">Company membership number</div>
                                    <div className="cw">{user.company_membership_number}</div>
                                  </div>

                                  <div className="custom-grid">
                                    <div className="cw1 font-bolder">Passport Photo</div>
                                    <div className="cw">
                                         {user.passport_photo ? 
                                             <img width={100} src={filebasepath()+user.passport_photo} / >
                                            : 'No Added' } 
                                      
                                      </div>
                                  </div>


                              </div>
                              
                          </div>
                        </div>

                      

                      

                     
                  </div>
              </div>
          </div>
      </div>
      : '' }
      
    </>

  );
}

export default Userview;
