import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { WebAssetsContext } from "../../App";
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Swal from 'sweetalert2';
const Companies = (props) => {
  const WebAssets = useContext(WebAssetsContext);
  const api = WebAssets.apiURL;
  const navigate = useNavigate();
  
  const [data, setData] = useState([]);
  const fetchData = () => {
    fetch(api + "getcompanies")
      .then(res => res.json())
      .then((result) => {
        if (result.status == true) {
          setData(result.response);
        }
      }
      )
  }

  const HandleClickOnDelete = async (id = '') => {
    Swal.fire({
      title: 'Are you sure you want to delete it ?',
      text: "Once deleted, you will not be able to get it again!",
      icon: 'warning',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          const resJson = DeleteAPiCall(id);
          resJson.then(function (output) {
            if (output.status == true) {
              Swal.fire({
                title: 'Deleted!',
                text: "Company has been deleted successfully.",
                icon: 'success'
              }).then((finalresult) => {
                if (finalresult.isConfirmed) {
                  fetchData();
                }
              });
            }
          });

        } catch (err) {
          console.log(err);
        }

      }
    });


  }
  const DeleteAPiCall = async (id = '') => {
    const res = await fetch(api + "deleteCompany", {
      method: "POST",
      body: JSON.stringify({ id: id }),
    });
    let resJson = await res.json();
    return resJson;
  }

useEffect(() => {
    fetchData();
  }, [])

  return (
    <>

      <div className="section-body">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="pt-4 pl-4 pr-4">
                        <div className="card-header pl-0 pr-0 w-50 float-left">
                          <h4 className="headercolor">Companies List</h4>
                        </div>
                        <div className="float-right">
                          <button onClick={() => navigate("/admin/companies/add")} className="btn btn-icon icon-left btn-primary"><i className="fas fa-plus"></i> Add Company</button>
                        </div>
                      </div>
                      <div className="card-body pl-4 pr-4 pb-4 pt-0">
                        <div className="table-responsive">
                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th className="text-center">S.NO</th>
                                <th width={'500px'}>Company Name</th>
                                <th>Status</th>
                                <th className="text-right" width={'250px'}>
                                    <span className="actionw-250">Action</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>

                              {data.length > 0 ? data.map((row, i) => (

                                <tr key={i}>
                                  <td className="text-center">{i + 1}</td>
                                  <td>{row.title}</td>
                                  <td>
                                    {row.status == '1' ? <div className="badge badge-success">Active</div> : <div className="badge badge-danger">In-Active</div>}
                                  </td>
                                  <td className="text-right">
                                    <button role="button"
                                      id={'edit_' + row.id} onClick={() => navigate("/admin/companies/edit/" + row.id)}
                                      className="btn btn-icon btn-primary mr-2" data-tooltip-content="Edit">
                                      <i className="far fa-edit"></i>
                                    </button>
                                    <Tooltip anchorId={'edit_' + row.id} />

                                    <button role="button" id={'delete_' + row.id} onClick={() => HandleClickOnDelete(row.id)} className="btn btn-icon btn-danger" data-tooltip-content="Delete" ><i className="far fa-trash-alt"></i></button>
                                    <Tooltip anchorId={'delete_' + row.id} />
                                  </td>
                                </tr>
                              )) :

                                <tr><td colSpan="10" className="text-center">Data not yet found. </td></tr>
                              }



                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
     
    </>

  );
}

export default Companies;
