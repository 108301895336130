
import { useState, createContext, useContext } from "react";
import { Link , NavLink  } from "react-router-dom";
import  { WebAssetsContext }  from "../../../App";
import  { getLoggedInuserRole }  from "../../../functions/Function";


const  Sidebar = (props) => {
  const WebAssets = useContext(WebAssetsContext);
  const [NavLinktoggle, setNavLinktoggle] = useState(false);

  return (
    
    <>
      
       <div className="main-sidebar sidebar-style-2  sticky sidebarClass">
        <aside id="sidebar-wrapper">
          <div className="sidebar-brand customMiniSmallDeviceparent">
            <NavLink to="/admin/dashboard"> <img width="110" alt={'Logo'}   src={WebAssets.logo} className="header-logo"  /> 
       
            </NavLink>
              <i  onClick={() => props.minibatToggle() } className="fa fa-arrow-left customMiniSmallDevice"></i>
          </div>
          <ul className="sidebar-menu">
            <li className="menu-header">Modules</li>
            <li className="dropdown">
              <NavLink  to="/admin/dashboard"    className="nav-link"><i className="fas fa-tachometer-alt"></i><span>Dashboard</span></NavLink >
            </li>
            {getLoggedInuserRole() == 'administrator' && 
            <li className="dropdown">
              <NavLink to="/admin/staff" className="nav-link"><i className="fas fa-user-check"></i><span>Staff Admin</span></NavLink>
            </li>
            }

            <li className="dropdown">
              <NavLink to="/admin/clients" className="nav-link"><i className="fas fa-users"></i><span>Clients</span></NavLink>
            </li>

           
            {getLoggedInuserRole() == 'administrator' && 
            <li className="dropdown">
              <NavLink to="/admin/branches" className="nav-link"><i className="fas fa-code-branch"></i><span>Branches</span></NavLink>
            </li>
            }
            
            {getLoggedInuserRole() == 'administrator' && 
            <li className="dropdown">
              <NavLink to="/admin/companies" className="nav-link"><i className="fas fa-hospital"></i><span>Companies</span></NavLink>
            </li>
             }
           
           {getLoggedInuserRole() == 'administrator' && 
            <li className="dropdown">
              <NavLink to="/admin/settings" className="nav-link"><i className="fas fa-cogs"></i><span>Settings</span></NavLink>
            </li>
            }
            
            </ul>
        </aside>

        <ul className="sidebar-menu">
        <li className="dropdown">
              <Link onClick={WebAssets.logout}  className="nav-link logoutLink"><i className="fas fa-sign-out"></i><span>logout</span></Link>
            </li>
        </ul>
      </div>
    </>

  );
}

export default Sidebar;
