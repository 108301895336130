
import React , { useState } from "react";
import { Link , NavLink  } from "react-router-dom";

const  Footer = (props) => {
  return (
    <>
        <footer className="main-footer">
        <div className="footer-left">
        client management system
        </div>
        <div className="footer-right">
        </div>
      </footer>
    </>

  );
}

export default Footer;
