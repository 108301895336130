import React , { useState , useEffect , useContext } from "react";
import { Link } from "react-router-dom";
import box1 from '../../assets/img/banner/1.png';
import box2 from '../../assets/img/banner/2.png';
import box3 from '../../assets/img/banner/3.png';
import box4 from '../../assets/img/banner/4.png';
import  { WebAssetsContext }  from "../../App";
import  { loggedInUserDetails }  from "../../functions/Function";
const  Dashboard = (props) => {
const WebAssets = useContext(WebAssetsContext);
const api = WebAssets.apiURL;
const [dashboarddata, setdashboarddata] = useState(null);
const fetchData = () => {
  fetch(api+"dashboard")
    .then(res => res.json())
    .then((result) => {
        if(result.status == true)
        {
          setdashboarddata(result.response);
        }
      }
    )
  }

  useEffect(() => {
    fetchData();

},[])
  return (
    <>
      {/* {console.log(dashboarddata.lasted_users)} */}
      
      {dashboarddata != null ? 
        <div>
         
         <div className="row">
              <div className="col-12">
                <h4>Hello {loggedInUserDetails('name')} , Welcome To Client Management System</h4>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="card">
                  <div className="card-body card-type-3">
                    <div className="row">
                      <div className="col">
                        <h6 className="text-muted mb-0">All Clients</h6>
                        <span className="font-weight-bold mb-0">{dashboarddata.total_client}</span>
                      </div>
                      <div className="col-auto">
                        <div className="card-circle l-bg-cyan text-white">
                          <i className="fas fa-users"></i>
                        </div>
                      </div>
                    </div>
                    <p className="mt-3 mb-0 text-muted text-sm">
                     
                      <span className="text-nowrap">Since All Time</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="card">
                  <div className="card-body card-type-3">
                    <div className="row">
                      <div className="col">
                        <h6 className="text-muted mb-0">Clients</h6>
                        <span className="font-weight-bold mb-0">{dashboarddata.total_client_current_month}</span>
                      </div>
                      <div className="col-auto">
                        <div className="card-circle l-bg-cyan text-white">
                          <i className="fas fa-users"></i>
                        </div>
                      </div>
                    </div>
                    <p className="mt-3 mb-0 text-muted text-sm">
                  
                      <span className="text-nowrap">This month</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="card">
                  <div className="card-body card-type-3">
                    <div className="row">
                      <div className="col">
                        <h6 className="text-muted mb-0">Total Staff</h6>
                        <span className="font-weight-bold mb-0">{dashboarddata.total_staff}</span>
                      </div>
                      <div className="col-auto">
                        <div className="card-circle l-bg-green text-white">
                          <i className="fas fa-user-check"></i>
                        </div>
                      </div>
                    </div>
                    <p className="mt-3 mb-0 text-muted text-sm">
                     
                      <span className="text-nowrap">Since All Time</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="card">
                  <div className="card-body card-type-3">
                    <div className="row">
                      <div className="col">
                        <h6 className="text-muted mb-0">Active Staff</h6>
                        <span className="font-weight-bold mb-0">{dashboarddata.total_active_staff}</span>
                      </div>
                      <div className="col-auto">
                        <div className="card-circle l-bg-green text-white">
                          <i className="fas fa-user-check"></i>
                        </div>
                      </div>
                    </div>
                    <p className="mt-3 mb-0 text-muted text-sm">
                     
                      <span className="text-nowrap">Active Staff</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6">
                <div className="card">
                  <div className="card-body card-type-3">
                    <div className="row">
                      <div className="col">
                        <h6 className="text-muted mb-0">Branches</h6>
                        <span className="font-weight-bold mb-0">{dashboarddata.total_branches}</span>
                      </div>
                      <div className="col-auto">
                        <div className="card-circle l-bg-purple text-white">
                          <i className="fas fa-code-branch"></i>
                        </div>
                      </div>
                    </div>
                    <p className="mt-3 mb-0 text-muted text-sm">
                  
                      <span className="text-nowrap">Since All Time</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6">
                <div className="card">
                  <div className="card-body card-type-3">
                    <div className="row">
                      <div className="col">
                        <h6 className="text-muted mb-0">Branches</h6>
                        <span className="font-weight-bold mb-0">{dashboarddata.total_active_branches}</span>
                      </div>
                      <div className="col-auto">
                        <div className="card-circle l-bg-purple text-white">
                          <i className="fas fa-code-branch"></i>
                        </div>
                      </div>
                    </div>
                    <p className="mt-3 mb-0 text-muted text-sm">
                  
                      <span className="text-nowrap">Active Branches</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6">
                <div className="card">
                  <div className="card-body card-type-3">
                    <div className="row">
                      <div className="col">
                        <h6 className="text-muted mb-0">Companies</h6>
                        <span className="font-weight-bold mb-0">{dashboarddata.total_companies}</span>
                      </div>
                      <div className="col-auto">
                        <div className="card-circle l-bg-orange text-white">
                        <i className="fas fa-hospital"></i>
                        </div>
                      </div>
                    </div>
                    <p className="mt-3 mb-0 text-muted text-sm">
                  
                      <span className="text-nowrap">Since All Time</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6">
                <div className="card">
                  <div className="card-body card-type-3">
                    <div className="row">
                      <div className="col">
                        <h6 className="text-muted mb-0">Companies</h6>
                        <span className="font-weight-bold mb-0">{dashboarddata.total_active_companies}</span>
                      </div>
                      <div className="col-auto">
                        <div className="card-circle l-bg-orange text-white">
                        <i className="fas fa-hospital"></i>
                        </div>
                      </div>
                    </div>
                    <p className="mt-3 mb-0 text-muted text-sm">
                  
                      <span className="text-nowrap">Active Companies</span>
                    </p>
                  </div>
                </div>
              </div>


            </div>
          {/* <div className="row">
          <div className="col-md-6 col-lg-12 col-xl-6">
                <div className="card">
                  <div className="card-header">
                    <h4 className="">Lasted Register Customer </h4>
                    <Link className="btn btn-primary btn-sm" to="/admin/users">View All</Link>
                  </div>
                  <div className="card-body pt-0">
                    <div className="table-responsive customtble">
                      <table className="table table-hover mb-0">
                        <thead>
                          <tr>
                          <th>NAME</th>
                          <th>EMAIL</th>
                        
                          <th width={'150px'} className="text-right">STATUS</th>
                          </tr>
                        </thead>
                        <tbody>
                        { dashboarddata.lasted_users != null ?
                          dashboarddata.lasted_users.map((user , i)  => ( 
                          <tr key={i}>
                            <td>{user.name}</td>
                            <td>{user.email} </td>
                            
                            <td className="text-right">

                            { user.status == '1' ? <div className="badge badge-success">Active</div> : <div className="badge badge-danger">In-Active</div>}
                            </td>
                            
                            
                          </tr>
                          )) : '' }

                          
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
            </div>
            <div className="col-md-6 col-lg-12 col-xl-6">
                <div className="card">
                  <div className="card-header">
                    <h4>Quick Buy Commodity Product</h4>
                    <Link className="btn btn-primary btn-sm" to="/admin/products">View All</Link>
                  </div>
                  <div className="card-body pt-0">
                    <div className="table-responsive customtble">
                      <table className="table table-hover mb-0">
                        <thead>
                          <tr>
                          <th>PRODUCT NAME</th>
                          <th>PRICE</th>
                          <th className="text-right">STATUS</th>
                          </tr>
                        </thead>
                        <tbody>
                        { dashboarddata.quick_buy_products != null ?
                          dashboarddata.quick_buy_products.map((product , i)  => ( 
                          <tr key={i}>
                            <td> {product.name.toUpperCase()}</td>
                            <td>{props.currencySymbol()}{product.price} </td>
                            <td className="text-right">

                            { product.status == '1' ? <div className="badge badge-success">Active</div> : <div className="badge badge-danger">In-Active</div>}
                            </td>
                            
                            
                          </tr>
                          )) : '' }

                          
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
            </div>
          </div> */}
      </div>
      : '' }
                
    </>

  );
}

export default Dashboard;
