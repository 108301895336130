import React , { Suspense, lazy , useState , createContext , useEffect, useRef } from "react";
import logo from './logo.png';
import tableuserDefaultImg from './assets/avatar.png';
import 'react-tooltip/dist/react-tooltip.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip'
import Login from './admin/Login';
import Layout from './components/admin/layout/Layout';
import Dashboard from './admin/dashboard/Dashboard';

import Users from './admin/users/Users';
import Useredit from './admin/users/Useredit';
import Useradd from './admin/users/Useradd';
import Userview from './admin/users/Userview';

import Branches from './admin/branches/Branches';
import Branchedit from './admin/branches/Branchedit';
import Branchadd from './admin/branches/Branchadd';

import Companies from './admin/companies/Companies';
import Companyedit from './admin/companies/Companyedit';
import Companyadd from './admin/companies/Companyadd';

import Staff from './admin/staff/Staff';
import Staffedit from './admin/staff/Staffedit';
import Staffadd from './admin/staff/Staffadd';

import Settings from './admin/Settings';
import Profile from './admin/Profile';
import Notfound from './admin/Notfound';
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes  ,  Redirect  , Route, Link ,  Navigate , useNavigate,   useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import packageJson from '../package.json';


import 
{ 
  changeDateFotmate,
  currencySymbol
} from './functions/Function';


const WebAssetsContext = createContext();

function App() {

  let getuserloggedIn = JSON.parse(localStorage.getItem("admin"));
  const [userloggedIn, setUserloggedIn] = useState(getuserloggedIn); 
  const logout = (e) => {
    e.preventDefault();
    localStorage.removeItem("admin");
    setUserloggedIn(null);
  };


  const LoggedInAdmin = () =>
  {
    let getuserloggedIn = JSON.parse(localStorage.getItem("admin"));
    if(getuserloggedIn)
    {
      setUserloggedIn(getuserloggedIn);
    }else
    {
      setUserloggedIn(null);
    }
  }
  const [AdminAssets, setAdminAssets] = useState(
      { 
        apiURL: 'https://projects.softechplanet.com/clientcrm/api/' ,
        //apiURL: 'http://crm.metrowaysvoyage.com/server/api/' ,
        logo: logo ,
        tableuserDefaultImg:tableuserDefaultImg,
        LoggedInAdmin:LoggedInAdmin,
        logout:logout,
      });

      const Protected = ({ children }) => {
          if (userloggedIn == null) {
              return <Navigate to="/admin/login" replace />;
          }
          return children;
        };
       
  useEffect(() => {
    
    
    
   
    LoggedInAdmin();
    console.log(packageJson.version);
  },[])  

  return (
    <>
      <WebAssetsContext.Provider value={AdminAssets}>
             
            <Router>
              
              <Routes>
              <Route path="/"  element={
                  <Protected>
                    <Navigate replace to={"/admin/dashboard"} />
                  </Protected>
                }  
                />
                <Route path="/admin" element={ <Protected><Layout /></Protected> }>
                 
                  <Route index  element={
                  <Protected>
                    <Navigate replace to={"/admin/dashboard"} />
                  </Protected>
                }  
                />
                <Route
                        
                      path="/admin/dashboard"
                      element={
                        <Protected>
                          <Dashboard 
                          changeDateFotmate={changeDateFotmate} 
                          currencySymbol={currencySymbol}  /> 
                        </Protected>
                      }
                  />

                <Route
                      exact
                      path="/admin/clients"
                      element={
                        <Protected>
                          <Users 
                          changeDateFotmate={changeDateFotmate} 
                          currencySymbol={currencySymbol} 
                          /> 
                        </Protected>
                      }
                  />

                  <Route
                      exact
                      path="/admin/clients/add"
                      element={
                        <Protected>
                            <Useradd /> 
                        </Protected>
                        }
                  />

                  <Route
                      exact
                      path="/admin/clients/edit/:id"
                      element={
                        <Protected>
                          <Useredit />
                      </Protected>
                      }
                  />

                  <Route
                      exact
                      path="/admin/clients/view/:id"
                      element={
                        <Protected>
                          <Userview />
                      </Protected>
                      }
                  />


                  <Route
                      exact
                      path="/admin/branches"
                      element={
                        <Protected>
                          <Branches 
                          changeDateFotmate={changeDateFotmate} 
                          currencySymbol={currencySymbol} 
                          /> 
                        </Protected>
                      }
                  />

                  <Route
                      exact
                      path="/admin/branches/add"
                      element={
                        <Protected>
                            <Branchadd /> 
                        </Protected>
                        }
                  />

                  <Route
                      exact
                      path="/admin/branches/edit/:id"
                      element={
                        <Protected>
                          <Branchedit />
                      </Protected>
                      }
                  />

                <Route
                      exact
                      path="/admin/companies"
                      element={
                        <Protected>
                          <Companies 
                          changeDateFotmate={changeDateFotmate} 
                          currencySymbol={currencySymbol} 
                          /> 
                        </Protected>
                      }
                  />

                  <Route
                      exact
                      path="/admin/companies/add"
                      element={
                        <Protected>
                            <Companyadd /> 
                        </Protected>
                        }
                  />

                  <Route
                      exact
                      path="/admin/companies/edit/:id"
                      element={
                        <Protected>
                          <Companyedit />
                      </Protected>
                      }
                  />

                  <Route
                      exact
                      path="/admin/staff"
                      element={
                        <Protected>
                          <Staff 
                          changeDateFotmate={changeDateFotmate} 
                          currencySymbol={currencySymbol} 
                          /> 
                        </Protected>
                      }
                  />   

                  <Route
                      exact
                      path="/admin/staff/add"
                      element={
                        <Protected>
                            <Staffadd /> 
                        </Protected>
                        }
                  />

                  <Route
                      exact
                      path="/admin/staff/edit/:id"
                      element={
                        <Protected>
                          <Staffedit />
                      </Protected>
                      }
                  /> 

                  

                  <Route
                      exact
                      path="/admin/settings"
                      element={
                        <Protected> 
                            <Settings />
                        </Protected>
                        }
                  />

                  
                  <Route
                      exact
                      path="/admin/profile"
                      element={
                        <Protected>
                            <Profile />
                        </Protected>
                      }
                  />

                  <Route
                      path="*"
                      element={<Notfound />}
                  />


                </Route>
                <Route
                    exact
                    path="/admin/login"
                    element={userloggedIn == null ? <Login /> : 
                    <Navigate replace to={"/admin/dashboard"} />
                  
                  
                  }
                />

                
              </Routes>

            

            </Router>
          </WebAssetsContext.Provider>
       
   
    </>
  );
}


export default App;
export { WebAssetsContext};



