import Moment from 'moment';
export const changeDateFotmate = (date='' , format='') => 
{
  return Moment(date).format(format);
}
export const currencySymbol = () =>   'XOF ';

export const filebasepath = () =>   'https://projects.softechplanet.com/clientcrm/uploads/';
export const apibaseURl = 'https://projects.softechplanet.com/clientcrm/api/';
//export const filebasepath = () =>   'http://crm.metrowaysvoyage.com/server/uploads/';
//export const apibaseURl = 'http://crm.metrowaysvoyage.com/server/api/';
export const loggedInUserDetails = (key='') => 
{
    let getuserloggedIn = JSON.parse(localStorage.getItem("admin"));
    if(getuserloggedIn.user[key] != undefined && getuserloggedIn.user[key] != '')
    {
      return getuserloggedIn.user[key];
    }

    return false;
};

export const getLoggedInuserRole = () => 
{
    let getuserloggedIn = JSON.parse(localStorage.getItem("admin"));
    if(getuserloggedIn.user['role'] != undefined && getuserloggedIn.user['role'] != '')
    {
      return getuserloggedIn.user['role'];
    }

    return false;
};

export const  apiCallpost = async (url , data) => 
{
    let res = await fetch(apibaseURl+url, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    return res;

};

export const  alertDanger =  (message) => 
{
     return (
      <div className="alert ivo-alert-text alert-danger" role="alert">
      <div className="row align-items-center vertical-align">
        <div className="col-xs-1 col-lg-1 col-1 text-center">
          <i className="fa fa-exclamation-triangle "></i>
        </div>
        <div className="col-xs-11 col-lg-11 col-11">
          <div className="ivo-alert-content">
            <strong>Alert:</strong> {message}

          </div>
        </div>
      </div>
      </div>
     );

};

export const  alertSuccess =  (message) => 
{
     return (
      <div className="alert ivo-alert-text alert-success" role="alert">
      <div className="row align-items-center vertical-align">
        <div className="col-xs-1 col-lg-1 col-1 text-center">
        <i className="fa-solid fa-circle-check"></i>
        </div>
        <div className="col-xs-11 col-lg-11 col-11">
          <div className="ivo-alert-content">
            <strong>Success:</strong> {message}

          </div>
        </div>
      </div>
      </div>
     );

};

export const getActivebranches =  () => {
  // const res =  fetch(apibaseURl + "getActivebranches", {
  //   method: "GET",
  // });
  // let resJson =  res.json();
  // if(res.status === 200)
  // {
  //   if(resJson.status == true)
  //   {
  //     return resJson.response;
  //   }
  // }

  // return false;

  return fetch(apibaseURl + "getActivebranches")
  .then((response) => response.json())
  .then((data) => data.response);
  
}








   