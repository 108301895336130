import React , { useState , useContext , useEffect } from "react";
import { Link , NavLink , useParams , useNavigate  } from "react-router-dom";
import  { WebAssetsContext }  from "../App";
import Swal from 'sweetalert2';
import { ToastContainer, toast , Zoom } from 'react-toastify';

const  Settings = (props) => {
  const WebAssets = useContext(WebAssetsContext);
  const api = WebAssets.apiURL;
  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState("");
  
  const errortoast = (text) => 
  {
    toast.error(text ,
       {
          autoClose: 3000,
          transition:Zoom,
          theme: "colored",
          closeOnClick: true,
          pauseOnHover: true,
        }
      );
  };
  const getSettingsData = async () => 
  {
    const res =  await fetch(api+"getSettings");
          let resJson = await res.json();
          return resJson;
  }

  const getSettings = async () => {
    try {
      const resJson = getSettingsData();
      resJson.then(function(output) {
        if(output.status === true)
        {
          setEmail(output.response.admin_email);
        }
    });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    try {
      let res = await fetch(api+"updatesettings", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
        }),
      });
      let output = await res.json();
      setDisabled(false);
      if (res.status === 200) {
        if(output.status === 2)
          {
            getvalidationErr(output.response);
          }
        if(output.status === true)
          {
            setDisabled(false);
            Swal.fire({  
              title: 'Success',  
              text: 'Settings update successfully!',
              icon: 'success'
            }).then((result) => {
              if (result.isConfirmed) {
                getSettings();
              } 
            });
          }

        }
    } catch (err) {
      setDisabled(false);
    }
  };

  const getvalidationErr = (errors) =>
  {
    const errorsConvertInArry = Object.values(errors);
    let collectAllValidationErr = [];
    errorsConvertInArry.forEach((error, index) => {
      collectAllValidationErr.push(<div key={index}>{error}</div>);
    });
    const storeAllValidtionErr = (
      <>
       {collectAllValidationErr}
      </>
    );
    errortoast(storeAllValidtionErr);
  }

  

  useEffect(() => {
    getSettings();
  },[])
  
  return (
    <>
    <ToastContainer />
    <div className="section-body">
                  <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h4>Settings</h4>
                                
                            </div>
                            <div className="card-body p-4">
                           
                            <form onSubmit={handleSubmit} id="create-course-form">
                                      <div className="row">
                                          <div className="col-sm-12">
                                              <div className="form-group">
                                                  <label>Admin Email <small className="text-danger">*</small></label>
                                                  <input
                                                      type="text"
                                                      value={email}
                                                      placeholder="Admin Email"
                                                      onChange={(e) => setEmail(e.target.value)}
                                                      className="form-control"
                                                    />
                                                   
                                              </div>
                                              
                                          </div>
                                         
                                          
                                          <div className="col-12">
                                            <div className="card-footer text-center">
                                                <button className="btn btn-primary mr-1"  type="submit" disabled={disabled}> {disabled == false ? 'Update': 'Updating...'} </button>
                                                
                                              </div>
                                          </div>
                                      </div>
                                  </form>
                            </div>
                        </div>
                      </div>
                  </div>
                  </div>
    </>

  );
}

export default Settings;
