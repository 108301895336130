import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { WebAssetsContext } from "../../App";
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Swal from 'sweetalert2';
import  { loggedInUserDetails }  from "../../functions/Function";
const Users = (props) => {
  const WebAssets = useContext(WebAssetsContext);
  const api = WebAssets.apiURL;
  const navigate = useNavigate();
  const HandleClickOnEditUser = (id = '') => {
    navigate("/admin/clients/edit/" + id);
  }
  const [users, setUsers] = useState([]);
  const fetchData = () => {
    fetch(api + "getusers")
      .then(res => res.json())
      .then((result) => {
        if (result.status == true) {
          setUsers(result.response);
        }
      }
      )
  }

  const fetchDataByClientBranchID = (branch_id='') => {
    fetch(api + "getusersByBranchID?branch_id="+branch_id)
      .then(res => res.json())
      .then((result) => {
        if (result.status == true) {
          setUsers(result.response);
        }
      }
      )
  }

  const HandleClickOnDeleteUser = async (id = '') => {
    Swal.fire({
      title: 'Are you sure you want to delete it ?',
      text: "Once deleted, you will not be able to get it again!",
      icon: 'warning',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          const resJson = DeleteUser(id);
          resJson.then(function (output) {
            if (output.status == true) {
              Swal.fire({
                title: 'Deleted!',
                text: "User has been deleted successfully.",
                icon: 'success'
              }).then((finalresult) => {
                if (finalresult.isConfirmed) {
                  fetchData();
                }
              });
            }
          });

        } catch (err) {
          console.log(err);
        }

      }
    });


  }
  const DeleteUser = async (id = '') => {
    const res = await fetch(api + "deleteUser", {
      method: "POST",
      body: JSON.stringify({ id: id }),
    });
    let resJson = await res.json();
    return resJson;
  }


  const [show, setShow] = useState(false);
  const [user, setUser] = useState(null);
  const HandleClickonAddWallet = (user) => {
    setShow(true)
    setUser(user)
  }

  const hideModal = () => {
    setShow(false);
  }
  useEffect(() => {
    if(loggedInUserDetails('role') == 'administrator' )
    {
      fetchData();
    }
    if(loggedInUserDetails('role') == 'staff')
    {
      fetchDataByClientBranchID(loggedInUserDetails('branch_id'));
    }
    
    
  }, [])

  return (
    <>
     
      <div className="section-body">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="pt-4 pl-4 pr-4">
                        <div className="card-header pl-0 pr-0 w-50 float-left">
                          <h4 className="headercolor">Clients List</h4>
                        </div>
                        <div className="float-right">
                          <button onClick={() => navigate("/admin/clients/add")} className="btn btn-icon icon-left btn-primary"><i className="fas fa-plus"></i> Add Client</button>
                        </div>
                      </div>
                      <div className="card-body pl-4 pr-4 pb-4 pt-0">
                        <div className="table-responsive">
                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th className="text-center">S.NO</th>
                                <th  width={'180px'} >Client Name</th>
                                { loggedInUserDetails('role') == 'administrator' ? 
                                  <>
                                    
                                    <th width={'180px'} >Email</th>
                                    <th width={'180px'} >Phone</th>
                                    
                                  </>
                                : 
                                  <>
                                      <th width={'180px'} >Added By</th>
                                      <th width={'180px'} >Branch</th>
                                  </>
                                }
                                <th width={'180px'} >City</th>
                                <th width={'180px'} >State</th>
                               
                                <th className="text-right" width={'350px'}>
                                    <span className="actionw-20">Action</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>

                              {users.length > 0 ? users.map((user, i) => (

                                <tr key={i}>
                                  <td className="text-center">{i + 1}</td>
                                  <td>{user.name.toLowerCase()}</td>
                                  { loggedInUserDetails('role') == 'administrator' ? 
                                  <>
                                      <td>{user.c_email}</td>
                                      <td>{user.c_phone}</td>
                                  </>
                                : 
                                  <>
                                      <td>{user.added_by_name}</td>
                                      <td>{user.branch_name}</td>
                                  </>
                                }
                                  
                                  <td>{user.city}</td>
                                  <td>{user.state}</td>
                                  
                                  <td className="text-right">

                                    <button role="button"
                                      id={'view_' + user.id} onClick={() => navigate("/admin/clients/view/" + user.id)}
                                      className="btn btn-icon btn-primary mr-2" data-tooltip-content="View Details">
                                      <i className="fas fa-eye"></i>
                                    </button>
                                    <Tooltip anchorId={'view_' + user.id} />
                                    
                                    <button role="button"
                                      id={'edit_' + user.id} onClick={() => HandleClickOnEditUser(user.id)}
                                      className="btn btn-icon btn-primary mr-2" data-tooltip-content="Edit">
                                      <i className="far fa-edit"></i>
                                    </button>
                                    <Tooltip anchorId={'edit_' + user.id} />
                                    { loggedInUserDetails('role') == 'administrator' &&
                                        <>
                                            <button role="button" id={'delete_' + user.id} onClick={() => HandleClickOnDeleteUser(user.id)} className="btn btn-icon btn-danger" data-tooltip-content="Delete" ><i className="far fa-trash-alt"></i></button>
                                            <Tooltip anchorId={'delete_' + user.id} />
                                        </>
                                    }
                                  </td>
                                </tr>
                              )) :

                                <tr><td colSpan="10" className="text-center">Data not yet found. </td></tr>
                              }



                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
     
    </>

  );
}

export default Users;
