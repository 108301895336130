import React, {useState , useContext , useEffect , useRef } from 'react';
import  { WebAssetsContext }  from "../App";
import axios from "axios";
import ProgressBar from 'react-bootstrap/ProgressBar';


const  Uploadfile = (props) => {
    const aRef = useRef(null);
    const WebAssets = useContext(WebAssetsContext);
    const api = WebAssets.apiURL;
    const [progress, setProgress] = useState(false);
	const [progresscount, setProgresscount] = useState(0);
	const [variant, setVariant] = useState('danger');

   

    const resetfiledata = () =>
    {
         setProgress(false);
         setProgresscount(0);
         setVariant('danger');
         aRef.current.value = null;
    }

    

    const changeHandler = (event) => {
        const formData = new FormData();
        var file = event.target.files[0];
        if(file === undefined)
        {
            return false;
        }
        const config = {
            onUploadProgress: function(progressEvent) {
            setProgress(true);
            setVariant('danger');
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            setProgresscount(percentCompleted); 
           
            console.log(percentCompleted)
            }
        }
        let data = new FormData()
        data.append('file', file)
        axios.post(api+"uploadfile", data, config)
            .then(function(res)
            {
                if(res.status == 200 && res.data.status == true)
                {
                    setVariant('success');
                    props.filedata(res.data.response);
                }
            }
            )
            .catch(err => console.log(err))
        };

       
        useEffect(() => {
            resetfiledata();
          },[props.resetfile])

    return(
            <>
            <div>
                { progress == true ? 
                <div className='mb-2'>
                <ProgressBar animated now={progresscount} label={`${progresscount}%`} 
                        variant={variant}
                /> </div>
                : ''    }
                <div className="form-group">
               
			    <input ref={aRef} type="file" className='form-control' name="file" onChange={changeHandler} />
                </div>
            </div>
            </>
	)
}
export default Uploadfile;